import {
    handleQueryResolve
} from '../utils'

export default function (patientID, rxNumber, genericName, brandName) {
  if (patientID) {
    const attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: patientID, // to support like
    }]
    const where = ['PatientID = @PatientID']

    if (rxNumber) {
      attrs.push({
        param: 'rxNumber',
        type: 'sql.VarChar(50)',
        value: `%${rxNumber}%`, // to support like
      })

      where.push('RXNumber LIKE @rxNumber')
    }

    if (genericName) {
      attrs.push({
        param: 'genericName',
        type: 'sql.VarChar(50)',
        value: `%${genericName}%`, // to support like
      })

      where.push('GenericName LIKE @genericName')
    }

    if (brandName) {
      attrs.push({
        param: 'brandName',
        type: 'sql.VarChar(50)',
        value: `%${brandName}%`, // to support like
      })

      where.push('BrandName LIKE @brandName')
    }

    return this.query(`
    SELECT  * FROM
    (SELECT
        "DBA"."Prescription"."RxNumber" AS "RxNumber",
        "DBA"."Prescription"."FillDate" AS "RxDate",
        CONVERT(CHAR(20),"DBA"."Prescription"."WB_Date",112) AS "WB_Date",
        "DBA"."Prescription"."InstructionText" AS "SIGFull",
        "DBA"."Prescription"."FillQuantity" AS "RxQtyDispense",
        "DBA"."Prescription"."FillQuantity" AS "QtyDispense",
        "DBA"."Prescription"."QuantityAuthorized" AS "TotalAuthorized",
        "DBA"."Drug"."GenericName" AS "GenericName",
        "DBA"."Drug"."DIN" AS "DIN",
        "DBA"."Drug"."TradeName" AS "BrandName",
        "DBA"."Drug"."Strength" AS "RxStrength",
        "DBA"."Prescription"."PatientId" AS "PatientID",
        "DBA"."Doctor"."LastName",
        "DBA"."Prescription"."DaysSupply" AS "Days",
        "DBA"."Prescription"."Status",
        "DBA"."Doctor"."FirstName",
        "DBA"."Doctor"."PrescriberId" AS "CPSO",
        ("DBA"."Prescription"."QuantityAuthorized"-"DBA"."Prescription"."QuantityAuthorizedUsed") AS "QtyRemain",
    RANK() OVER (PARTITION BY "DBA"."Drug"."GenericName", "DBA"."Drug"."Strength", "DBA"."Prescription"."Status"  ORDER BY  WB_Date  DESC) AS Rank
        FROM ( ( "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id" ) JOIN "DBA"."DrugForm" ON "DBA"."Drug"."FormCode" = "DBA"."DrugForm"."Code" ) JOIN "DBA"."Doctor" ON "DBA"."Prescription"."DoctorId" = "DBA"."Doctor"."Id"
        AND (FillDate > DATEADD( month,  -13, GetDate() )  OR WB_Date > DATEADD( month,  -13, GetDate() ))  AND "DBA"."Prescription"."PatientId" = @PatientID ) AS DT
    WHERE Rank = 1
    ORDER BY RxDate

        `, attrs)

      .then(value => {
        const values = value.map(data => {
          const modifiedData = { ...data }
          modifiedData.Days = Math.trunc(parseInt(data.Days, 10))
          modifiedData.RxDate = !modifiedData.RxDate ? modifiedData.WB_Date : modifiedData.RxDate

          return modifiedData
        })

        return values
      })
      .then(handleQueryResolve)
  }
}
